import styled from "@emotion/styled";
import { fromZonedTime } from "date-fns-tz";
import { useState } from "react";

import { DaysPicker } from "./days-picker";
import { MonthPicker } from "./month-picker";
import { WeekDay } from "../../../../types";
import { convertToZonedTime } from "../../../../utils";

const Container = styled.div`
  max-width: 48rem;

  .heading {
    margin: 0 0 0.6rem 0.6rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
  }
`;

type DatePickerProps = {
  heading?: string;
  selectedDate: string | undefined | null;
  startDate?: string;
  toDate?: string;
  excludingWeekDays?: WeekDay[];
  excludingDates?: string[];
  disabled?: boolean;
  viewingTimezone?: string | null;
  onChange: (date: string) => void;
};

export const DatePicker = ({
  heading,
  selectedDate,
  startDate,
  toDate,
  excludingWeekDays,
  excludingDates,
  disabled,
  viewingTimezone,
  onChange,
}: DatePickerProps) => {
  const [{ year, month }, setYearMonth] = useState(() => {
    const initialDateInUtc = selectedDate
      ? fromZonedTime(selectedDate, viewingTimezone || "UTC")
      : new Date();
    const initialDateInViewingTimezone = convertToZonedTime(
      initialDateInUtc,
      viewingTimezone,
    );

    return {
      year: initialDateInViewingTimezone.getFullYear(),
      month: initialDateInViewingTimezone.getMonth(),
    };
  });

  return (
    <Container data-testid="intake-date-picker">
      {heading && <div className="heading">{heading}</div>}
      <MonthPicker
        year={year}
        month={month}
        onChange={setYearMonth}
        disabled={disabled}
        viewingTimezone={viewingTimezone}
      />
      <DaysPicker
        selectedDate={selectedDate}
        year={year}
        month={month}
        startDate={startDate}
        toDate={toDate}
        excludingWeekDays={excludingWeekDays}
        excludingDates={excludingDates}
        onChange={onChange}
        disabled={disabled}
        viewingTimezone={viewingTimezone}
      />
    </Container>
  );
};
