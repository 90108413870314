import "./styles.css";

import { createRoot } from "react-dom/client";

import { withFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { initMetrics } from "@smart/bridge-metrics-dom";
import { IntakeApp } from "@smart/intake-components-dom";

initMetrics();

const App = withFeatureFlags(IntakeApp);
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
