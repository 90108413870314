import styled from "@emotion/styled";
import { addMinutes, format } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  AppointmentResponse,
  MAX_DATES_TO_DISPLAY,
  MeetingType,
  StaffDetails,
} from "@smart/bridge-types-basic";
import { Button, Icon } from "@smart/itops-components-dom";
import { Combobox } from "@smart/itops-ui-dom";
import { buildTimeZoneList, localTimeZoneCode } from "@smart/itops-utils-basic";

import { DatePicker } from "./date-picker";
import { TimePicker } from "./time-picker";
import { fieldName } from "../../../hooks";
import { SelectedAppointment } from "../../../types";
import {
  formatTimeOfDay,
  getDateString,
  timeOfDayInMinutes,
  useAvailableSlots,
} from "../../../utils";
import { ErrorDisplay } from "../error";
import { Label } from "../label";
import { FieldProps, FieldWrapper } from "../wrapper";

const Wrapper = styled.fieldset`
  background: var(--background);
  border: 1px solid ${(props) => props.theme.palette.disabled.base};
  border-radius: ${(props) => props.theme.baseUnit}rem;
  padding: ${(props) => props.theme.baseUnit}rem
    ${(props) => props.theme.baseUnit * 2}rem;
`;

const SelectionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: ${(props) => props.theme.palette.foreground.base};
  font-size: ${(props) => props.theme.fontSize.base};
`;

const LabelWrapper = styled.legend`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  margin-left: ${(props) => props.theme.baseUnit * -0.6}rem;
  padding: 0 ${(props) => props.theme.baseUnit}rem 0;
`;

const Top = styled.div`
  gap: 1rem;
  padding-bottom: 1.6rem;
  margin-bottom: 2rem;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.disabled.base};
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSize.base};

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }

  .select-team-member {
    flex: 1;
    margin: 0 0 1.6rem 0;
  }

  .info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.6rem 0;

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
      flex-direction: row;
    }

    .information {
      flex: 1;
      display: flex;
      align-items: center;

      .icon {
        color: ${(props) => props.theme.palette.secondary.base};
      }

      .text {
        margin-left: 1rem;
        color: ${(props) => props.theme.palette.secondary.base};
      }
    }
  }

  .selection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;

    .dropdown {
      margin-top: 0.6rem;
      width: 100%;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 5.8rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0 0.4rem 0;
  margin-top: 1rem;

  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${(props) => props.theme.palette.disabled.base};

  button {
    margin: 0.5rem 0 0 0;
    padding: 1rem;
    border-radius: 6px;
  }
`;

const DateTimePickerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 34rem;
  justify-content: space-around;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    height: 40rem;
  }
`;

const intakeMeetingTypeLabel: Record<MeetingType, string> = {
  inPerson: "In-person meeting",
  telephoneConsult: "Phone meeting",
};

export const AppointmentField = ({
  field: fieldValues,
  value,
  error,
  disabled,
  onBlur,
  onChange,
  index,
  staffDetails,
  loading,
}: FieldProps & { staffDetails: StaffDetails[] }) => {
  const {
    availableStaffIds,
    availability,
    duration,
    meetingType,
    timezone: fieldTimeZone,
    minimumNotice,
    bufferTime,
  } = fieldValues;

  const id = fieldName({ field: fieldValues, index });
  const errorId = fieldName({ field: fieldValues, index, suffix: "error" });

  const timeZoneOptions = buildTimeZoneList().map((tz) => ({
    ...tz,
    label: tz.label.split("/").pop() || "",
  }));
  const localTimeZone = timeZoneOptions.find(
    (tz) => tz.tzCode === localTimeZoneCode,
  );
  const availableStaff = useMemo(
    () => staffDetails.filter((s) => s && availableStaffIds?.includes(s.id)),
    [availableStaffIds, staffDetails],
  );

  const getTimeZone = (ofTimeZoneCode: string | undefined) =>
    timeZoneOptions.find((tz) => tz.tzCode === ofTimeZoneCode);

  const getDefaultValues = (
    response: AppointmentResponse | undefined,
  ): SelectedAppointment => {
    const usedTimeZone = getTimeZone(response?.timezone) || localTimeZone;
    const startTimeAsDate =
      response?.startTime && usedTimeZone
        ? toZonedTime(response.startTime, usedTimeZone.tzCode)
        : null;
    const defaultTeamMember =
      availableStaff.length === 1
        ? {
            id: availableStaff[0].id,
            firstName: availableStaff[0].firstName,
            middleName: availableStaff[0].middleName || undefined,
            lastName: availableStaff[0].lastName || "",
          }
        : null;

    return {
      selectedTeamMember: response?.bookedStaff?.[0] || defaultTeamMember,
      selectedDate: startTimeAsDate && getDateString(startTimeAsDate),
      selectedTime:
        startTimeAsDate && response?.endTime
          ? {
              hour: startTimeAsDate.getHours(),
              minute: startTimeAsDate.getMinutes(),
            }
          : undefined,
      timezone: usedTimeZone,
    };
  };

  const formMethods = useForm<SelectedAppointment>({
    defaultValues: getDefaultValues(value),
  });

  const { control, handleSubmit, watch, setValue } = formMethods;
  const selectedTeamMember = watch("selectedTeamMember");
  const selectedDate = watch("selectedDate");
  const selectedTime = watch("selectedTime");
  const selectedTimezone = watch("timezone");

  const staffBusySlots = useMemo(() => {
    if (!selectedTeamMember) return [];

    const bookedStaffDetails = staffDetails.find(
      (d) => d.id === selectedTeamMember.id,
    );
    return (bookedStaffDetails?.busySlots || []).map((s) => ({
      fromTime: s.startTime,
      toTime: s.endTime,
      timezone: s.timezone,
    }));
  }, [selectedTeamMember]);

  const {
    startDate,
    toDate,
    excludingDates,
    availableTimeSlotsForSelectedDate: availableTimeSlots,
  } = useAvailableSlots({
    selectedDateInViewingTimezone: selectedDate,
    availability: availability || [],
    creationTimezone: fieldTimeZone || undefined,
    duration: duration || 30,
    blocked: staffBusySlots,
    viewingTimezone: selectedTimezone?.tzCode || localTimeZoneCode,
    minimumNoticeInMinutes: minimumNotice || undefined,
    bufferTimeInMinutes: bufferTime || undefined,
    numberOfDisplayDays: MAX_DATES_TO_DISPLAY,
    deps: [
      fieldValues,
      selectedDate,
      selectedTimezone,
      staffBusySlots,
      minimumNotice,
      bufferTime,
    ],
  });

  const submit = handleSubmit(async (values) => {
    const selectedTzCode = values.timezone?.tzCode || localTimeZoneCode;
    const selectedStartTime = values.selectedDate
      ? new Date(values.selectedDate)
      : undefined;
    if (selectedStartTime && values.selectedTime) {
      selectedStartTime.setHours(values.selectedTime.hour);
      selectedStartTime.setMinutes(values.selectedTime.minute);
      selectedStartTime.setSeconds(0);
    }
    const selectedEndTime = selectedStartTime
      ? addMinutes(selectedStartTime, duration || 15)
      : undefined;
    const isValid =
      values.selectedTeamMember && values.selectedDate && values.selectedTime;

    const appointment = {
      bookedStaff: values.selectedTeamMember
        ? [
            {
              id: values.selectedTeamMember.id,
              firstName: values.selectedTeamMember.firstName,
              middleName: values.selectedTeamMember.middleName || undefined,
              lastName: values.selectedTeamMember.lastName,
            },
          ]
        : undefined,
      startTime: selectedStartTime
        ? fromZonedTime(selectedStartTime, selectedTzCode).toISOString()
        : undefined,
      endTime:
        selectedEndTime && isValid
          ? fromZonedTime(selectedEndTime, selectedTzCode).toISOString()
          : undefined,
      timezone: selectedTzCode,
      status: isValid ? "pending" : "invalid",
    };
    onChange(appointment);
    onBlur();
  });

  useEffect(() => {
    if (value === null) {
      setValue("selectedTeamMember", null);
      setValue("selectedDate", null);
      setValue("selectedTime", null);
    }
  }, [value]);

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Wrapper
        id={id}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
      >
        <LabelWrapper>
          <Label {...fieldValues} index={index} />
        </LabelWrapper>
        <FormProvider {...formMethods} key={selectedTimezone?.tzCode}>
          <Top>
            <Row>
              <div className="selection">
                <div>Select team member</div>
                <div className="dropdown">
                  <Controller
                    control={control}
                    name="selectedTeamMember"
                    render={({ field, fieldState }) => (
                      <Combobox
                        className="select-team-member"
                        id={field.name}
                        title="Select a team member"
                        options={availableStaff}
                        empty="No options"
                        getOptionLabel={(staff) =>
                          [staff.firstName, staff.middleName, staff.lastName]
                            .filter(Boolean)
                            .join(" ")
                        }
                        getOptionKey={(staff) => staff.id}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        error={!!fieldState.error}
                        {...field}
                        onChange={async (_, v) => {
                          if (v?.id !== selectedTeamMember?.id) {
                            setValue("selectedDate", null);
                            setValue("selectedTime", null);
                            field.onChange(v);
                            await submit();
                          }
                        }}
                        multiple={false}
                        size="base"
                        hideClear
                      />
                    )}
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="info-container">
                <div className="information">
                  <Icon
                    library="lucide"
                    name="Clock"
                    size={16}
                    className="icon"
                  />
                  <div className="text">{`${duration} minutes`}</div>
                </div>
                <div className="information">
                  <Icon
                    library="lucide"
                    name={
                      meetingType === "telephoneConsult" ? "Phone" : "Users"
                    }
                    size={16}
                    className="icon"
                  />
                  <div className="text">
                    {intakeMeetingTypeLabel[meetingType || "inPerson"]}
                  </div>
                </div>
              </div>
              <div className="selection">
                <div>Timezone</div>
                <div className="dropdown">
                  <Controller
                    control={control}
                    name="timezone"
                    render={({ field, fieldState }) => (
                      <Combobox
                        className="select-time-zone"
                        icon={{ library: "lucide", name: "Globe" }}
                        id={field.name}
                        title="Timezone"
                        options={timeZoneOptions}
                        empty="No options"
                        getOptionLabel={(tz) => tz.label}
                        getOptionKey={(tz) => tz.tzCode}
                        isOptionEqualToValue={(o, v) => o.tzCode === v.tzCode}
                        groupBy={(tz) => tz.region}
                        error={!!fieldState.error}
                        {...field}
                        onChange={async (_, v) => {
                          setValue("selectedDate", null);
                          setValue("selectedTime", null);
                          field.onChange(v);
                          await submit();
                        }}
                        size="base"
                        multiple={false}
                        disableClearable
                      />
                    )}
                  />
                </div>
              </div>
            </Row>
          </Top>
          <DateTimePickerContainer>
            <Controller
              control={control}
              name="selectedDate"
              render={({ field }) => (
                <DatePicker
                  heading="Date"
                  selectedDate={field.value}
                  startDate={startDate}
                  toDate={toDate}
                  excludingDates={excludingDates}
                  onChange={async (date) => {
                    field.onChange(date);
                    await submit();
                  }}
                  disabled={!selectedTeamMember?.id}
                  viewingTimezone={selectedTimezone?.tzCode}
                />
              )}
            />
            <Controller
              control={control}
              name="selectedTime"
              render={({ field }) => (
                <TimePicker
                  heading="Time"
                  selectedTime={field.value}
                  availableTimes={availableTimeSlots}
                  onSelect={async (time) => {
                    if (
                      selectedTime &&
                      timeOfDayInMinutes(time) ===
                        timeOfDayInMinutes(selectedTime)
                    ) {
                      field.onChange(null);
                    } else {
                      field.onChange(time);
                    }
                    await submit();
                  }}
                />
              )}
            />
          </DateTimePickerContainer>
          <Bottom>
            <SelectionInfo>
              <Icon library="lucide" name="Info" size={16} />
              {selectedTime ? (
                <span>
                  You have selected{" "}
                  <b>
                    {selectedDate ? format(selectedDate, "dd MMM yyyy") : ""}
                  </b>{" "}
                  at <b>{selectedTime ? formatTimeOfDay(selectedTime) : ""}</b>{" "}
                  for your meeting
                </span>
              ) : (
                <span>
                  A meeting <b>will not</b> be scheduled as you have not
                  selected a time
                </span>
              )}
            </SelectionInfo>
            {selectedTime && (
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  setValue("selectedDate", null);
                  setValue("selectedTime", null);
                  await submit();
                }}
              >
                Clear selection
              </Button>
            )}
          </Bottom>
        </FormProvider>
      </Wrapper>
      <ErrorDisplay uri={fieldValues.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
