import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Controller, useForm } from "react-hook-form";

import {
  checkHasResponse,
  SubmissionSyncStatus,
} from "@smart/bridge-types-basic";
import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import { UploadFileProps } from "@smart/itops-ui-dom";
import { entriesOf, fromEntries } from "@smart/itops-utils-basic";

import {
  fieldName,
  fieldValidation,
  getRequiredMessage,
  renderOrderedItems,
  useLoadMatterFields,
  useOrderedErrors,
  useOrderedItems,
  Visibility,
} from "../../hooks";
import {
  AutoFillStatus,
  ConnectionItem,
  FieldItem,
  GroupItem,
  IntakeFormSectionRef,
  LoadResponses,
  LookupOptions,
  ResponseSubmitter,
  SectionItem,
  SubmissionItem,
  TeamItem,
  ToastContent,
} from "../../types";
import { IntakeField } from "../field";
import { FieldsList } from "../field-components";
import { IntakeGroup } from "../group";
import { IntakeSection } from "../section";

export type IntakeFormSectionProps = {
  connection?: ConnectionItem;
  autoFillSection?: SectionItem;
  visibleSections: SectionItem[];
  selected: SectionItem;
  groups: GroupItem[];
  fields: FieldItem[];
  onSelect: (section: SectionItem) => void;
  onSummary: () => void;
  loadResponses: LoadResponses;
  submitResponses: ResponseSubmitter;
  setResponses: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setStatus: (status: SubmissionItem["status"]) => void;
  team: TeamItem;
  sidebar: boolean;
  responses: Record<string, any>;
  visibility: Visibility;
  disabled?: boolean;
  isPreview?: boolean;
  previewOptions?: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    previewResponses: [
      Record<string, any>,
      React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ];
  };
  isUpdating?: boolean;
  setVisited: (section: SectionItem) => void;
  staffDetails: StaffDetails[];
  submissionSyncStatus?: SubmissionSyncStatus | null;
  showConnectionBanner?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  autoFillStatus?: AutoFillStatus;
  toast?: ToastContent;
  closeToast?: () => void;
  loading?: boolean;
  aiFillFromMatterInfo?: boolean;
} & LookupOptions & { uploadFileProps: UploadFileProps };

export const IntakeFormSection = forwardRef(
  (
    {
      addressLookup,
      fileLookup,
      connection,
      autoFillSection,
      visibleSections,
      selected,
      groups,
      fields,
      onSelect,
      onSummary,
      loadResponses,
      submitResponses,
      setResponses,
      setStatus,
      team,
      sidebar,
      responses,
      visibility,
      disabled,
      previewOptions,
      isUpdating,
      setVisited,
      uploadFileProps,
      staffDetails,
      submissionSyncStatus,
      showConnectionBanner,
      autoFillStatus,
      toast,
      closeToast,
      loading,
      aiFillFromMatterInfo,
      isPreview,
    }: IntakeFormSectionProps,
    forwardedRef: ForwardedRef<IntakeFormSectionRef>,
  ) => {
    const orderedItems = useOrderedItems({
      selected,
      groups,
      fields,
      visibility,
    });

    const form = useForm({
      defaultValues: previewOptions?.previewResponses[0] || responses,
    });

    const errorsRef = useRef<HTMLDivElement>(null);
    const hideErrors = previewOptions?.previewErrorToggle[0];
    const showNext = () => {
      if (connection?.status === "error" && !hideErrors) return;

      const selectedIndex = selected
        ? visibleSections.findIndex((s) => s.uri === selected?.uri)
        : -1;
      if (selectedIndex !== -1) setVisited(visibleSections[selectedIndex]);

      if (visibleSections.length === 1) setStatus("completed");
      const next = visibleSections[selectedIndex + 1];
      if (next) {
        onSelect(next);
      } else {
        onSummary();
      }
    };

    const onSubmit = (options?: { nextAction?: () => void; skip?: boolean }) =>
      options?.skip
        ? options.nextAction || (() => {})
        : form.handleSubmit(
            (values) => {
              if (visibleSections.length === 1) {
                const status = "completed";
                submitResponses({ responses: values, status })
                  .then((result) => {
                    if (result?.operationIds) {
                      setResponses((current) => ({ ...current, ...values }));
                      setStatus(status);
                    }
                  })
                  .catch(() => {});
                setVisited(visibleSections[0]);
              } else if (options?.nextAction) {
                options.nextAction();
              }
            },
            (errors) => {
              if (entriesOf(errors).length > 1) {
                setTimeout(() => errorsRef?.current?.focus(), 5);
              }
            },
          );

    const hasRequired = orderedItems.some((i) =>
      i.type === "field"
        ? i.field.mandatory
        : i.fields.some((f) => f.mandatory),
    );
    const errors = useOrderedErrors(
      { orderedItems, responses },
      form.formState,
    );

    useLoadMatterFields(
      {
        submissionSyncStatus,
        fn: () => {
          entriesOf(responses).forEach(([fieldUri, value]) => {
            const currentValue = form.getValues(fieldUri);
            if (
              !currentValue ||
              (Array.isArray(currentValue) &&
                !currentValue.filter(Boolean).length)
            ) {
              const field = fields.find((f) => f.uri === fieldUri);
              if (!field) return;

              const isGroup = !!field.groupUri;
              if (Array.isArray(value) && isGroup) {
                value.forEach((v, index) => {
                  form.setValue(fieldName({ field, index }), v);
                });
              } else {
                form.setValue(fieldName({ field }), value);
              }
            }
          });
        },
      },
      [responses],
    );

    useEffect(() => {
      if (previewOptions) {
        previewOptions.previewResponses[1]((prevPreviewResponses) => ({
          ...prevPreviewResponses,
          ...responses,
        }));
      }
    }, [responses]);

    useEffect(() => {
      if (selected.validateOnDisplay) {
        form.trigger().catch(console.error);
      }
    }, []);

    useImperativeHandle(forwardedRef, () => ({
      submitForm: onSubmit(),
      errors,
      connection,
    }));

    const selectedIndex = visibleSections.findIndex(
      (s) => s.uri === selected?.uri,
    );

    const showBack = () => {
      if (selectedIndex > 0) {
        onSelect(visibleSections[selectedIndex - 1]);
      } else if (selectedIndex === 0 && autoFillSection) {
        onSelect(autoFillSection);
      }
    };

    const getValidationRules = (field: FieldItem) => {
      if (disabled || field.type === "info") {
        return undefined;
      }

      const optionalSpecialField =
        ["appointment", "payment"].includes(field.type) && !field.mandatory;

      return {
        required: field.mandatory ? getRequiredMessage(field.type) : undefined,
        validate: optionalSpecialField
          ? undefined
          : fieldValidation[field.type],
      };
    };

    const isNonRepeatableField = (field: FieldItem) => field.type === "payment";

    const autoFillInProgress = autoFillStatus === "filling";

    return (
      <IntakeSection
        connection={connection}
        team={team}
        sidebar={sidebar}
        autoFillSection={autoFillSection}
        sections={visibleSections}
        selected={{ ...selected, hasRequired }}
        onSubmit={hideErrors ? showNext : onSubmit({ nextAction: showNext })}
        hasPayment={Object.values(responses).some((r) => !!r?.chargeRequest)}
        onPreviousButton={
          hideErrors
            ? showBack
            : onSubmit({
                nextAction: showBack,
                skip: !isUpdating || selectedIndex === 0,
              })
        }
        onNextButton={disabled ? onSubmit({ nextAction: showNext }) : undefined}
        onSubmitButton={
          disabled ? onSubmit({ nextAction: showNext }) : undefined
        }
        errors={errors}
        isForm={!disabled}
        errorsRef={errorsRef}
        previewErrorToggle={previewOptions?.previewErrorToggle}
        showConnectionBanner={showConnectionBanner}
        retrySubmitResponses={() => {
          const values = form.getValues();
          submitResponses({
            responses: values,
          })
            .then((result) => {
              if (result?.operationIds) {
                setResponses((current) => ({
                  ...current,
                  ...values,
                }));
              }
            })
            .catch(() => {});
        }}
        autoFillStatus={autoFillStatus}
        toast={toast}
        closeToast={closeToast}
        loading={loading}
        aiFillFromMatterInfo={aiFillFromMatterInfo}
      >
        <FieldsList>
          {renderOrderedItems({
            orderedItems,
            responses,
            renderGroup: ({
              key,
              group,
              fields: groupFields,
              length,
              index,
              children,
            }) => (
              <IntakeGroup
                key={key}
                group={group}
                index={index}
                onAdd={
                  group.repeatable &&
                  length <= (group.maxRepeat || 30) &&
                  index === length - 1
                    ? () => {
                        if (disabled) return;

                        const field = groupFields[0];
                        const toUpdate = form.getValues(field.uri).slice(0);
                        toUpdate.splice(index + 1, 0, null);
                        const updated = {
                          [field.uri]: toUpdate,
                        };

                        form.setValue(field.uri, toUpdate);
                        submitResponses({
                          responses: updated,
                          lastUpdatedFieldUri: field.uri,
                          lastUpdatedSectionUri: selected.uri,
                        })
                          .then((result) => {
                            if (result?.operationIds) {
                              setResponses((current) => ({
                                ...current,
                                ...updated,
                              }));
                            }
                          })
                          .catch(() => {});
                      }
                    : undefined
                }
                onRemove={
                  group.repeatable && length > (group.minRepeat || 1)
                    ? () => {
                        if (disabled) return;

                        const updated: Record<string, any> = {};
                        for (const field of groupFields.filter(
                          (f) => !isNonRepeatableField(f),
                        )) {
                          const toUpdate = form.getValues(field.uri).slice(0);
                          toUpdate.splice(index, 1);
                          updated[field.uri] = toUpdate;
                          form.setValue(field.uri, toUpdate);
                        }

                        submitResponses({
                          responses: updated,
                          lastUpdatedFieldUri: groupFields[0].uri,
                          lastUpdatedSectionUri: selected.uri,
                        })
                          .then((result) => {
                            if (result?.operationIds) {
                              setResponses((current) => ({
                                ...current,
                                ...updated,
                              }));
                            }
                          })
                          .catch(() => {});
                      }
                    : undefined
                }
              >
                <FieldsList>{children}</FieldsList>
              </IntakeGroup>
            ),
            renderField: ({ key, field, index }) => {
              // Render only one field if it's non repeatable
              if (isNonRepeatableField(field) && index && index > 0)
                return null;

              return (
                <Controller
                  key={key}
                  control={form.control}
                  name={fieldName({
                    field,
                    index: isNonRepeatableField(field) ? undefined : index,
                  })}
                  rules={getValidationRules(field)}
                  render={({
                    field: { ref, value, onChange, onBlur },
                    fieldState: { error },
                  }) => {
                    const isLoadingMatterInfo = !!(
                      submissionSyncStatus === "loading" &&
                      field.layout &&
                      field.field &&
                      !checkHasResponse(field.type, value)
                    );
                    return (
                      <IntakeField
                        key={key}
                        innerRef={ref}
                        addressLookup={addressLookup}
                        fileLookup={fileLookup}
                        uploadFileProps={uploadFileProps}
                        loadResponses={loadResponses}
                        field={field}
                        index={index}
                        value={value}
                        isPreview={isPreview || !!previewOptions}
                        error={hideErrors ? undefined : error?.message}
                        loading={isLoadingMatterInfo}
                        disabled={
                          disabled || isLoadingMatterInfo || autoFillInProgress
                        }
                        onBlur={() => {
                          if (disabled) return onBlur();

                          const updated = {
                            [field.uri]: form.getValues(field.uri),
                          };

                          const willBeHidden = visibility.willBeHidden(updated);
                          const fieldMap = fromEntries(
                            fields.map((f) => [f.uri, f]),
                          );
                          for (const uri of willBeHidden) {
                            if (fieldMap[uri]?.type !== "payment") {
                              updated[uri] = null;
                              form.setValue(uri, null);
                            }
                          }

                          submitResponses({
                            responses: updated,
                            lastUpdatedFieldUri: field.uri,
                            lastUpdatedSectionUri: selected.uri,
                          })
                            .then((result) => {
                              if (result?.operationIds)
                                setResponses((current) => ({
                                  ...current,
                                  ...updated,
                                }));
                            })
                            .catch(() => {});

                          return onBlur();
                        }}
                        onChange={disabled ? () => {} : onChange}
                        staffDetails={staffDetails}
                      />
                    );
                  }}
                />
              );
            },
          })}
        </FieldsList>
      </IntakeSection>
    );
  },
);
