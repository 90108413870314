import styled from "@emotion/styled";
import { addDays, isAfter } from "date-fns";
import { useCallback } from "react";

import {
  WeekDay,
  calendarWeekDays,
  calendarWeekShortLabel,
} from "../../../../types";
import { convertToZonedTime, getDateString } from "../../../../utils";

const CalendarDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 1%;
`;

const WeekDayHeader = styled.div`
  width: 14%;
  height: 3rem;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${(props) => props.theme.fontSize.base};
`;

const Day = styled.div<{
  isCurrentMonth?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
  disabled?: boolean;
}>`
  width: 14%;
  height: 3.6rem;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isCurrentMonth ? "1" : "0")};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => (props.isToday ? "600" : "inherit")};
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.disabled.base
      : props.theme.palette.foreground.base};
  text-decoration: ${(props) => (props.isToday ? "underline" : "none")};
  text-underline-offset: 0.5rem;
  text-decoration-thickness: 2px;

  cursor: ${(props) =>
    props.disabled || !props.isCurrentMonth ? "default" : "pointer"};

  .day-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
    border-radius: 0.6rem;
    border: 1px solid;
    border-color: ${(props) =>
      props.isSelected ? props.theme.palette.active.accent : "transparent"};

    background: ${(props) =>
      props.isSelected ? props.theme.palette.active.highlight : "transparent"};

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.palette.active.accent};
      background: ${(props) =>
        props.disabled
          ? "transparent"
          : props.theme.palette.background.highlight};
    }
  }
`;

type DaysPickerProps = {
  selectedDate: string | undefined | null;
  year: number;
  month: number;
  startDate?: string;
  toDate?: string;
  excludingWeekDays?: WeekDay[];
  excludingDates?: string[];
  disabled?: boolean;
  viewingTimezone?: string | null;
  onChange: (date: string) => void;
};

export const DaysPicker = ({
  selectedDate,
  year,
  month,
  startDate,
  toDate,
  excludingWeekDays,
  excludingDates,
  disabled,
  viewingTimezone,
  onChange,
}: DaysPickerProps) => {
  const renderDays = useCallback(() => {
    const todayInViewingTimezone = getDateString(
      convertToZonedTime(new Date(), viewingTimezone),
    );
    const days = [];
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);
    const startOfWeek = (startOfMonth.getDay() + 6) % 7;
    const endOfWeek = (endOfMonth.getDay() + 6) % 7;
    const daysInMonth = endOfMonth.getDate();

    for (let day = 0; day < startOfWeek; day += 1) {
      days.push(
        <Day
          key={`prev-month-${day}`}
          isCurrentMonth={false}
          isSelected={false}
        />,
      );
    }

    for (let day = 1; day <= daysInMonth; day += 1) {
      const dateString = getDateString(new Date(year, month, day));

      const canSelect =
        isAfter(dateString, addDays(startDate || todayInViewingTimezone, -1)) &&
        (!toDate || isAfter(addDays(toDate, 1), dateString));
      const excluding =
        excludingWeekDays?.includes(new Date(dateString).getDay()) ||
        excludingDates?.some((d) => d === dateString);
      const isDisabled = disabled || !canSelect || excluding;

      days.push(
        <Day
          key={`current-month-${day}`}
          isCurrentMonth
          isSelected={!!selectedDate && selectedDate === dateString}
          isToday={dateString === todayInViewingTimezone}
          disabled={isDisabled}
          onClick={() => !isDisabled && onChange(dateString)}
          aria-label={dateString}
        >
          <div className="day-container">{day}</div>
        </Day>,
      );
    }

    for (let day = endOfWeek + 1; day < 7; day += 1) {
      days.push(
        <Day
          key={`next-month-${day}`}
          isCurrentMonth={false}
          isSelected={false}
        />,
      );
    }

    return days;
  }, [year, month, selectedDate, disabled]);

  return (
    <CalendarDays>
      {calendarWeekDays.map((wd) => (
        <WeekDayHeader key={wd}>{calendarWeekShortLabel[wd]}</WeekDayHeader>
      ))}
      {renderDays()}
    </CalendarDays>
  );
};
