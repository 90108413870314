import styled from "@emotion/styled";
import { compareAsc, compareDesc } from "date-fns";
import { useMemo } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { Combobox, Label } from "@smart/itops-ui-dom";

import { calendarMonthsShort } from "../../../../types";
import { convertToZonedTime } from "../../../../utils";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const SelectionGroup = styled.div`
  display: flex;
  gap: 1rem;

  .mobile-display-only {
    font-size: ${(props) => props.theme.fontSize.base};
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    .selection {
      display: none;
    }
    .mobile-display-only {
      display: block;
    }
  }
`;

type MonthPickerProps = {
  year: number;
  month: number;
  yearRange?: number;
  disabled?: boolean;
  onChange: (value: { year: number; month: number }) => void;
  viewingTimezone?: string | null;
};

export const MonthPicker = ({
  year,
  month,
  onChange,
  yearRange = 2,
  disabled,
  viewingTimezone,
}: MonthPickerProps) => {
  const today = convertToZonedTime(new Date(), viewingTimezone);
  const currentYear = today.getFullYear();

  const yearOptions = useMemo(() => {
    const options = [];
    for (let count = 0; count < yearRange; count += 1) {
      options.push(currentYear + count);
    }

    return options;
  }, [yearRange, currentYear]);
  const earliestDate = new Date(yearOptions[0], 0, 1);
  const latestDate = new Date(yearOptions[yearRange - 1] + 1, 0, 1);

  const isOutOfRange = (date: Date) =>
    compareDesc(date, latestDate) !== 1 ||
    compareAsc(date, earliestDate) === -1;

  const changeMonth = ({
    toMonth,
    toYear,
    byDistance = 0,
  }: {
    toMonth?: number;
    toYear?: number;
    byDistance?: number;
  }) => {
    const yearToUse = toYear || year;
    const monthToUse = toMonth === undefined ? month : toMonth;
    const date = new Date(yearToUse, monthToUse + byDistance, 1);

    if (isOutOfRange(date)) return;

    onChange({
      year: date.getFullYear(),
      month: date.getMonth(),
    });
  };

  return (
    <Container>
      <IconButton
        name="angleLeft"
        onClick={() => !disabled && changeMonth({ byDistance: -1 })}
        disabled={disabled}
        aria-label="Previous month"
      />
      <SelectionGroup>
        <Label className="mobile-display-only">
          {calendarMonthsShort[month]}
        </Label>
        <Label className="mobile-display-only">{year}</Label>
        <Combobox
          className="selection"
          title="Month"
          options={calendarMonthsShort}
          disableClearable
          value={calendarMonthsShort[month]}
          size="base"
          multiple={false}
          onChange={(_, v) => {
            const toMonth = calendarMonthsShort.findIndex((m) => m === v);
            changeMonth({ toMonth });
          }}
          disabled={disabled}
        />
        <Combobox
          className="selection"
          title="Year"
          options={yearOptions}
          value={year}
          getOptionLabel={(v) => v.toString()}
          disableClearable
          multiple={false}
          size="base"
          onChange={(_, v) => {
            changeMonth({ toYear: v });
          }}
          disabled={disabled}
        />
      </SelectionGroup>
      <IconButton
        name="angleRight"
        onClick={() => !disabled && changeMonth({ byDistance: 1 })}
        disabled={disabled}
        aria-label="Next month"
      />
    </Container>
  );
};
