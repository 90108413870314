import { useState } from "react";

import { useIdentify } from "@smart/bridge-feature-flags-dom";
import { AutoFillStatus } from "@smart/bridge-intake-components-dom";
import { identifyTrackingUser, trackChanged } from "@smart/bridge-metrics-dom";
import { Loading } from "@smart/itops-components-dom";

import { SubmissionForm } from "./form";
import { intakeApi, useRiskAnalysis } from "../../hooks";
import { NotFound } from "../not-found";

export type SubmissionLoadViewProps = {
  slug: string;
  id: string;
  hideTeam?: boolean;
};

export const SubmissionLoadView = ({
  slug,
  id,
  hideTeam,
}: SubmissionLoadViewProps) => {
  const {
    result,
    status,
    fetch: loadSubmission,
    refetch: reloadSubmission,
  } = intakeApi.load({ body: { slug, id }, retry: 10 });
  const { identifyFeatureFlagUser } = useIdentify();
  useRiskAnalysis();

  const [autoFillStatus, setAutoFillStatus] = useState<AutoFillStatus>();

  trackChanged(result, "Submission Loaded", (r) => {
    if (!r || !r.form || !r.submission) return undefined;

    if (r.team) {
      identifyTrackingUser({
        source: r.team.source,
        team: { uri: r.team.uri, name: r.team.name },
      });
      identifyFeatureFlagUser({
        team: {
          uri: r.team.uri,
          name: r.team.name,
          provider: r.team.source,
        },
      });
    }

    return {
      formUri: r.form.uri,
      submissionUri: r.submission.uri,
    };
  });

  if (
    autoFillStatus !== "filling" &&
    (status === "initial" || status === "loading")
  )
    return <Loading />;

  if (result) {
    const { matter, team, form, submission, staffDetails, aiUserFeedback } =
      result;
    if (team && form && submission)
      return (
        <SubmissionForm
          matter={matter}
          team={team}
          form={form}
          submission={submission}
          aiUserFeedback={aiUserFeedback}
          autoFillStatus={autoFillStatus}
          setAutoFillStatus={setAutoFillStatus}
          hideTeam={hideTeam}
          staffDetails={staffDetails || []}
          loadResponses={async () => {
            const loaded = await loadSubmission();
            return loaded?.submission?.responses;
          }}
          reloadSubmission={reloadSubmission}
        />
      );
  }

  return <NotFound />;
};
